/**
 * @description Show notification when user tries to add product to cart and it's disabled
 */
import {on} from "@elements/dom-utils"
import {onFind} from "@elements/init-modules-in-scope";
import {showNotification} from "@elements/alert-notification";

const selectors = {
    base: '.js-disabled-cart-info',
    disabledBtnWrapper: '.js-add-to-cart-disabled-info',
};

/**
 * @description Initialize the module and add event listener to the base element if it exists.
 * Needs to register the click on a wrapper around the button because the button is disabled and the click event won't fire.
 */
export function init() {
    onFind(selectors.base, function (base) {
        on('click', handleRowClick, base);
    });

}

/**
 * @description Show notification when user tries to add product to cart and it's disabled
 * @param {Event} evt
 */
const handleRowClick = (evt) => {
    const notificationTitle = evt.target.getAttribute('data-disabled-cart-info-title');
    const notificationMessage = evt.target.getAttribute('data-disabled-cart-info-message');

    evt.preventDefault();

    if (evt.target.matches(selectors.disabledBtnWrapper)) {
        showNotification({
            type: 'warning',
            title: notificationTitle,
            content: notificationMessage,
            closable: true,
            styleModifier: 'alert-cart',
            icon: 'warning'
        });
    }
}
let app = {
    debug: false,
};
window.app = app || {};

import * as tracking from "@elements/tracking";
let trackingOptions = {debug: true}; // optional options
tracking.initGtmEventTracking(trackingOptions); // for GTM tracking

import * as formTracking from './form-tracking';
formTracking.init();

import * as toc from '@elements/toc';
toc.init({linkClass: 'visually-hidden-focusable'});

import * as skipLinks from '@elements/skip-links';
skipLinks.init();

import * as lazyImg from '@elements/lazy-img';
lazyImg.init();

import * as tab from './tab';
tab.init();

import * as tooltip from './tooltip';
tooltip.init();

import * as responsiveTable from './responsive-table';
responsiveTable.init();

import * as availabilityStatus from './availability-status';
availabilityStatus.init();

import * as autocomplete from './autocomplete';
autocomplete.init();

import * as autocompleteSales from './sales-autocomplete';
autocompleteSales.init();

import * as rememberCollapse from './rememberCollapse';
rememberCollapse.init();

import * as scrollSlider from '@elements/scroll-slider';
scrollSlider.init();

import * as scrollTop from './scroll-top';
scrollTop.init();

import * as deleteItem from './delete-item';
deleteItem.init();

import * as wishlist from './wishlist';
wishlist.init();
wishlist.initInScope();

import * as additionalFormfields from './additional-formfields';
additionalFormfields.init();

import * as infoPopup from './info-popup';
infoPopup.init();

import * as inputSettings from './input-settings';
inputSettings.init();

import * as hashCash from './hashCash';
hashCash.init();

import * as dynamicModal from './dynamic-modal'
dynamicModal.init();

import * as cart from './cart'
cart.init();

import * as floatingLabels from '@elements/floating-labels';
floatingLabels.init();

import * as numberSpinner from '../libs/@elements/numberSpinner';
numberSpinner.init();

import * as formValidation from '@elements/form-validation';
formValidation.init({
    trigger:{event: 'false'}
});

// Add common JS that is shared between all page types here
import * as affix from "@elements/affix";
affix.init();

import * as lightbox from '@elements/lightbox';
if(!_config.admin) {
    lightbox.init({
        plugins: ['video']
    });
}

import * as nav from './nav';
nav.init();

import * as ajaxFormWithValidation from './ajax-form-with-validation';
ajaxFormWithValidation.init();

/*import * as ajaxForm from '../libs/@elements/ajax-form';
app.ajaxForm = ajaxForm.init();*/

import * as ajaxFormCustomReset from './ajaxFormCustomReset';
app.ajaxFormCustomReset = ajaxFormCustomReset.init();

import * as ajaxFormScroll from './ajaxFormScroll';
app.ajaxFormScroll = ajaxFormScroll.init();

import * as anchorNav from '@elements/anchor-nav/bootstrap-5';
anchorNav.init({
        renderItem: function (text, anchor) {
            return '<li class="main-nav__item anchor-nav__item"><a class="main-nav__item-content main-nav__item-content--level-0 js-smoothscroll__link anchor-nav__link" href="#' + anchor + '"><span class="icon icon-chevron anchor-nav__link-icon"></span>'+ text + '</span></a></li>';
        },
        smoothScroll: false,
        scrollSpy: !!document.getElementsByClassName('js-anchor-nav__item'),
        scrollSpyTarget: '.js-anchor-nav',
        scrollSpyOffset:180
    },
    {
        list: '.js-anchor-nav__list',
        item: '.js-anchor-nav__item'
    }
);

import * as smoothScroll from "@elements/smooth-scroll";
let selectors = {
    base: '#main-content',
    link: '.js-smoothscroll__link',
    dataOffset: 'smoothscroll-offset'
}
let options = {
    scrollOffset: 180, initialPosition: true
}
smoothScroll.init(options, selectors);

import * as pricecalcForm from '../libs/price-calc-form';
app.pricecalcForm = pricecalcForm.init();

import * as multiPriceCalc from './multi-price-calc';
multiPriceCalc.init();

import * as redirectForm from './redirect-form';
redirectForm.init();

import * as calculator from './calculator';
calculator.init();

import * as paperCalculator from './paper-calculator';
paperCalculator.init();

// import * as renderTemplate from './render-template';
// renderTemplate.init();
//
import * as contentVisibility from './content-visibility';
contentVisibility.init();

import * as modal from './modal';
modal.init();

import * as dropdown from './dropdown';
dropdown.init();

import * as validateZip from './validate-zip';
validateZip.init();

import * as numberCounterAnimation from './number-counter-animation';
numberCounterAnimation.init();

import * as profiling from './profiling';
profiling.init();

import {init as initAlertNotification} from '@elements/alert-notification';
initAlertNotification({
    closable: true
});

import * as sharing from './sharing-popup';
sharing.init();

import * as addToCartDisabledInfo from './add-to-cart-disabled-info';
addToCartDisabledInfo.init();

import Collapse from 'bootstrap/js/dist/collapse';

import * as trackingComponents from './tracking.js'
trackingComponents.initDetailedSearchTracking()
trackingComponents.initEcommerceTracking()